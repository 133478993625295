import React, { useState } from 'react';
import './stressTestInput.css'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Button, IconButton, InputAdornment, Slider,Menu,} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CustomSnackbar from '../CustomSnackBar/CustomSnackBar';


const StressTestInputForm = ({ onSubmit, onToggleWeightForm, isLoading }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isWeightFormVisible, setIsWeightFormVisible] = useState(false);
  const [choiceValue, setChoiceValue] = useState('');
  const [textValue, setTextValue] = useState('');
  const [weightValue, setweightValue] = useState(0)
  const [energyValue, setEnergyValue] = useState(14);
  const [materialsValue, setMaterialsValue] = useState(14);
  const [industrialsValue, setIndustrialsValue] = useState(14);
  const [consumerDiscretionaryValue, setConsumerDiscretionaryValue] = useState(14);
  const [healthcareValue, setHealthcareValue] = useState(14);
  const [financialsValue, setFinancialsValue] = useState(15);
  const [informationTechnologyValue, setInformationTechnologyValue] = useState(15);

  const [unitedStatesValue, setUnitedStatesValue] = useState(25);
  const [europeValue, setEuropeValue] = useState(25);
  const [asiaValue, setAsiaValue] = useState(25);
  const [emergingMarketsValue, setEmergingMarketsValue] = useState(25);

  const [equitiesValue, setEquitiesValue] = useState(25);
  const [fixedIncomeValue, setFixedIncomeValue] = useState(25);
  const [cashEquivalentsValue, setCashEquivalentsValue] = useState(25);
  const [alternativeInvestmentsValue, setAlternativeInvestmentsValue] = useState(25);
  const [anchorEl, setAnchorEl] = useState(null);

  const inputContainerClassName = textValue.length < 100
  ? 'input-container'
  : 'input-container-nonfixed';

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const displaySnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleSubmit = (e) => {
      e.preventDefault();
      if (choiceValue === "sectors") {
          const totalPercentage = parseInt(energyValue) + parseInt(materialsValue) +
              parseInt(industrialsValue) + parseInt(consumerDiscretionaryValue) +
              parseInt(healthcareValue) + parseInt(financialsValue) + parseInt(informationTechnologyValue);

          if (totalPercentage <= 100) {
              const formData = {
                  choice: choiceValue,
                  text: textValue,

                  energy: energyValue,
                  materials: materialsValue,
                  industrials: industrialsValue,
                  consumerDiscretionary: consumerDiscretionaryValue,
                  healthcare: healthcareValue,
                  financials: financialsValue,
                  informationTechnology: informationTechnologyValue,
              };
              onSubmit(formData);
              setTextValue('');
          } else {
              // Display an error message or handle the validation error as needed
              //   alert('Total percentage cannot exceed 100%.');
              displaySnackbar('Total percentage cannot exceed 100%.');
          }
      }
      if (choiceValue === "regions") {
          const totalPercentage = parseInt(unitedStatesValue) + parseInt(europeValue) +
              parseInt(asiaValue) + parseInt(emergingMarketsValue);

          if (totalPercentage <= 100) {
              const formData = {
                  choice: choiceValue,
                  text: textValue,

                  unitedStates: unitedStatesValue,
                  europe: europeValue,
                  asia: asiaValue,
                  emergingMarkets: emergingMarketsValue,
              };
              onSubmit(formData);
              setTextValue('');
          } else {
              // Display an error message or handle the validation error as needed
              //   alert('Total percentage cannot exceed 100%.');
              displaySnackbar('Total percentage cannot exceed 100%.');
          }
      }
      if (choiceValue === "asset_classes") {
          const totalPercentage = parseInt(equitiesValue) + parseInt(fixedIncomeValue) +
              parseInt(cashEquivalentsValue) + parseInt(alternativeInvestmentsValue);

          if (totalPercentage <= 100) {
              const formData = {
                  choice: choiceValue,
                  text: textValue,

                  equities: equitiesValue,
                  fixedIncome: fixedIncomeValue,
                  cashEquivalents: cashEquivalentsValue,
                  alternativeInvestments: alternativeInvestmentsValue,
              };
              console.log(formData)
              onSubmit(formData);
              setTextValue('');
          } else {
              // Display an error message or handle the validation error as needed
              //   alert('Total percentage cannot exceed 100%.');
              displaySnackbar('Total percentage cannot exceed 100%.');
          }
      }
  };

  const toggleWeightForm = () => {
    console.log('hello')
    setIsWeightFormVisible(!isWeightFormVisible);
    onToggleWeightForm();
   
  };

  // Define the slider to render based on the choice value
  let weightSlider;
  if (choiceValue === 'sectors') {
    weightSlider = (
        <div className="weightSelection">
          <div style={{marginBottom:'5px'}}>
            <label htmlFor="energy">Energy:</label>
            <input
              type="range"
              id="energy"
              name="energy"
              min="0"
              max="100"
            
              value={energyValue}
              
            />
            <input
              type="number"
              id="energy"
              name="energy"
              min="0"
              max="100"
              value={energyValue}
              onChange={ (e) => setEnergyValue(e.target.value)}
                
        className='sliderInput'
            />
          </div>
          <div style={{marginBottom:'5px'}}>
            <label htmlFor="materials">Materials:</label>
            <input
              type="range"
              id="materials"
              name="materials"
              min="0"
              max="100"
              value={materialsValue}
             
            />
           
            <input
              type="number"
              id="materials"
              name="materials"
              min="0"
              max="100"
              value={materialsValue}
              onChange={(e) => setMaterialsValue(e.target.value)}
              className='sliderInput'
            />
          </div>
          <div style={{marginBottom:'5px'}}>
            <label htmlFor="industrials">Industrials:</label>
            <input
              type="range"
              id="industrials"
              name="industrials"
              min="0"
              max="100"
              value={industrialsValue}
              
            />
            <input
              type="number"
              id="industrials"
              name="industrials"
              min="0"
              max="100"
              value={industrialsValue}
              onChange={ (e) => setIndustrialsValue(e.target.value)}
              className='sliderInput'
            />
          </div>
          <div style={{marginBottom:'5px'}}>
            <label htmlFor="consumerDiscretionary">Consumer Discretionary:</label>
            <input
              type="range"
              id="consumerDiscretionary"
              name="consumerDiscretionary"
              min="0"
              max="100"
              value={consumerDiscretionaryValue}
             
            />
             <input
              type="number"
              id="consumerDiscretionary"
              name="consumerDiscretionary"
              min="0"
              max="100"
              value={consumerDiscretionaryValue}
              onChange={(e) => setConsumerDiscretionaryValue(e.target.value)}   
              className='sliderInput'
            />
          </div>
          <div style={{marginBottom:'5px'}}>
            <label htmlFor="healthcare">Healthcare:</label>
            <input
              type="range"
              id="healthcare"
              name="healthcare"
              min="0"
              max="100"
              value={healthcareValue}
              
            />
             <input
              type="number"
              id="healthcare"
              name="healthcare"
              min="0"
              max="100"
              value={healthcareValue}
              onChange={(e) => setHealthcareValue(e.target.value)} 
              className='sliderInput'
            />
          </div>
          <div style={{marginBottom:'5px'}}>
            <label htmlFor="financials">Financials:</label>
            <input
              type="range"
              id="financials"
              name="financials"
              min="0"
              max="100"
              value={financialsValue}
            />
             <input
              type="number"
              id="financials"
              name="financials"
              min="0"
              max="100"
              value={financialsValue}
              onChange={(e) => setFinancialsValue(e.target.value)}   
              className='sliderInput'
            />
          </div>
          <div style={{marginBottom:'5px'}}>
            <label htmlFor="informationTechnology">Information Technology:</label>
            <input
              type="range"
              id="informationTechnology"
              name="informationTechnology"
              min="0"
              max="100"
              value={informationTechnologyValue}
             
            />
             <input
              type="number"
              id="informationTechnology"
              name="informationTechnology"
              min="0"
              max="100"
             
              value={informationTechnologyValue}
              onChange={(e) => setInformationTechnologyValue(e.target.value)}   
              className='sliderInput'
            />
          </div>
        </div>
    );
  } else if (choiceValue === 'asset_classes') {
    weightSlider = (
        <div className="weightSelection">
          <div style={{marginBottom:'5px'}}>
            <label htmlFor="equities">Equities:</label>
            <input
              type="range"
              id="equities"
              name="equities"
              min="0"
              max="100"
              value={equitiesValue}
              
            />
             <input
              type="number"
              id="equities"
              name="equities"
              min="0"
              max="100"
              value={equitiesValue}
              onChange={(e) => setEquitiesValue(e.target.value)} 
              className='sliderInput'
            />
          </div>
          <div style={{marginBottom:'5px'}}>
            <label htmlFor="fixedIncome">Fixed Income:</label>
            <input
              type="range"
              id="fixedIncome"
              name="fixedIncome"
              min="0"
              max="100"
              value={fixedIncomeValue}
            />
            <input
              type="number"
              id="equities"
              name="equities"
              min="0"
              max="100"
              value={fixedIncomeValue}
              onChange={(e) => setFixedIncomeValue(e.target.value)}
              className='sliderInput'
            />
          </div>
          <div style={{marginBottom:'5px'}}>
            <label htmlFor="cashEquivalents">Cash Equivalents:</label>
            <input
              type="range"
              id="cashEquivalents"
              name="cashEquivalents"
              min="0"
              max="100"
              value={cashEquivalentsValue}
             
            />
            <input
              type="number"
              id="cashEquivalents"
              name="cashEquivalents"
              min="0"
              max="100"
              value={cashEquivalentsValue}
              onChange={(e) => setCashEquivalentsValue(e.target.value)}
              className='sliderInput'
            />
          </div>
          <div style={{marginBottom:'5px'}}>
            <label htmlFor="alternativeInvestments">Alternative Investments:</label>
            <input
              type="range"
              id="alternativeInvestments"
              name="alternativeInvestments"
              min="0"
              max="100"
              value={alternativeInvestmentsValue}
            />
           <input
              type="number"
              id="alternativeInvestments"
              name="alternativeInvestments"
              min="0"
              max="100"
              value={alternativeInvestmentsValue}
              onChange={(e) => setAlternativeInvestmentsValue(e.target.value)}
              className='sliderInput'
            />
          </div>
        </div>
    );
  } else if (choiceValue === 'regions') {
    weightSlider = (
        <div className="weightSelection">
          <div style={{marginBottom:'5px'}}>
            <label htmlFor="unitedStates">United States:</label>
            <input
              type="range"
              id="unitedStates"
              name="unitedStates"
              min="0"
              max="100"
              value={unitedStatesValue}
            />
            <input
              type="number"
              id="unitedStates"
              name="unitedStates"
              min="0"
              max="100"
              value={unitedStatesValue}
              onChange={(e) => setUnitedStatesValue(e.target.value)}
              className='sliderInput'
            />
          </div>
          <div style={{marginBottom:'5px'}}>
            <label htmlFor="Europe">Europe:</label>
            <input
              type="range"
              id="europe"
              name="europe"
              min="0"
              max="100"
              value={europeValue}
            />
            <input
              type="number"
              id="europe"
              name="europe"
              min="0"
              max="100"
              value={europeValue}
              onChange={(e) => setEuropeValue(e.target.value)}
              className='sliderInput'
            />
          </div>
          <div style={{marginBottom:'5px'}}>
            <label htmlFor="asia">Asia:</label>
            <input
              type="range"
              id="asia"
              name="asia"
              min="0"
              max="100"
              value={asiaValue}
            />
           <input
              type="number"
              id="asia"
              name="asia"
              min="0"
              max="100"
              value={asiaValue}
              onChange={(e) => setAsiaValue(e.target.value)}
              className='sliderInput'
            />
          </div>
          <div style={{marginBottom:'5px'}}>
            <label htmlFor="emergingMarkets">Emerging Markets:</label>
            <input
              type="range"
              id="emergingMarkets"
              name="emergingMarkets"
              min="0"
              max="100"
              value={emergingMarketsValue}
            />
              <input
              type="number"
              id="emergingMarkets"
              name="emergingMarkets"
              min="0"
              max="100"
              value={emergingMarketsValue}
              onChange={(e) => setEmergingMarketsValue(e.target.value)}
              className='sliderInput'
            />
          </div>
        </div>
    );
  }

  return (

    <>
    <CustomSnackbar
      open={snackbarOpen}
      message={snackbarMessage}
      handleClose={handleSnackbarClose}
    />
     <div className={inputContainerClassName}style={{ height: '200px'}}>
    <form onSubmit={handleSubmit}>
        <h4>Stress Testing Specifications</h4>
        <br/>
        
    <div style={{display:'flex',flexDirection:'row',alignItems:'end',justifyContent:'center'}}>
   
        <FormControl style={{width:'450px',marginRight:'10px'}}>
        <InputLabel id="factor-selection" style={{padding: '0 5px'}}>Factor types</InputLabel>
        
        <Select
        labelId='factor-selection'
         id="Factor types"
        value={choiceValue}
       onChange={(e) => setChoiceValue(e.target.value)}
          
         >
      
           <MenuItem value={'sectors'}>Sectors</MenuItem>
           <MenuItem value={'asset_classes'}>Asset Classes</MenuItem>
           <MenuItem value={'regions'}>Regions</MenuItem>
          </Select>
        </FormControl>
       
        {/* <FormControl style={{width:'400px',marginRight:'10px'}}>
        <InputLabel id="Weights">Weights</InputLabel> 
        
        <Select
           labelId="Weights"
          id="Weights"
          onChange={toggleWeightForm}
         >
             <p style={{padding:'10px'}}> Weight Selection</p>
            {choiceValue === 'sectors' && (
               
                 <MenuItem value={'Option 1'}>{weightSlider}</MenuItem>
               
                 
            )}
              {choiceValue === 'asset_classes' && (
               
                  <MenuItem value={'Option 1'}>{weightSlider}</MenuItem>
              
                 
            )}
            {choiceValue === 'regions' && (
              
                  <MenuItem value={'Option 1'}>{weightSlider}</MenuItem>
              
                 
            )}

           
          </Select>
        </FormControl> */}
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
        {isWeightFormVisible && weightSlider}

       
         {/* < Button type="button" className="weightButton" onClick={toggleWeightForm} variant='outlined'>
        Weights 
      </Button> */}
       <Button
       className='weightButton' 

          aria-controls="weight-menu"
          aria-haspopup="true"
          onClick={handleClick}
          variant='outlined'
        >
          Weights
        </Button>
        <Menu
          id="weight-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
            {choiceValue === '' && <p style={{padding:'0 20px', height:'0'}}> Please select a factor type first</p>}
            {choiceValue !== ''&& <p style={{padding:'7px 15px', margin:'0'}}>Weight Selection</p>}
          <MenuItem className='custom-menu'>{weightSlider}</MenuItem>
        
          </Menu>
        </div>
       
       
    </div>
    <br/>
    <div style={{alignItems:'center',justifyContent:'center'}}>
    {/* <Box
      sx={{
        width: 1000,
        maxWidth: '100%',
      }}
    > */}
     <TextField
    size='large'
             label="Describe the event you want to consider to stress your portfolio performances"
             multiline
            rowsMax={5}
            value={textValue}
                onChange={(e) => setTextValue(e.target.value)}
             
            variant="outlined"
            InputProps = {{
                endAdornment :(
                    <InputAdornment position='end'>
                        <IconButton edge='end' type="submit" onClick={handleSubmit} disabled={isLoading}>
                        <SendIcon/>
                        </IconButton>
                       
                    </InputAdornment>
                )
            }}
           
          />
            
          </div>
    {/* </Box> */}
    
    {/* </div> */}
    </form>
    </div>
          </>
         
  );
};

export default StressTestInputForm;


