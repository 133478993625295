import React, { useState, useRef, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import './App.css';
import './styles.css';
import ToggleSwitch from './ToggleSwitch';
import InputForm from './InputForm';
import OptimalWeights from './OptimalWeights';
import API_BASE_URL from './config.js';
import TopPanel from './components/TopPanel';
import messageIcon from './assets/icons/message-text-circle.png';
import StressTestInputForm from './components/StressTestInputForm/stressTestInputForm';
import CircularProgress from '@mui/material/CircularProgress';

function App() {
  const [inputText, setInputText] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const chatRef = useRef(null); // Ref to scroll container
  const [isChatMode, setIsChatMode] = useState(false);
  const [isWeightFormVisible, setIsWeightFormVisible] = useState(false);
  const [chartOptions, setChartOptions] = useState(null);
  const [optimalChartOptions, setOptimalChartOptions] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  const handleInputHeightChange = (event) => {
    event.target.style.height = 'auto'; // Reset the height to auto
    event.target.style.height = `${(event.target.scrollHeight - 20)}px`; // Set the height to match the content
  };

  const toggleMode = () => {
    setIsChatMode(!isChatMode);
  };

  const handleSend = async () => {
    if (inputText.trim() === '') return; // Don't send empty messages

    // Create a new user message and add it to the chat
    const newUserMessage = { text: inputText, user: true };
    setChatMessages((prevMessages) => [...prevMessages, newUserMessage]);

    // Reset the input field
    setInputText('');

    // Send the user input to the backend and update chat messages
    await sendMessage(inputText, false);
  };

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      await handleSend();
    }
  };

  // Function to clear the input field
  const clearInput = () => {
    setInputText('');
  };

  // ... other state and functions ...

  const handleFormSubmit = async (value) => {
    setisLoading(true)
    // Handle the form submission with the positive integer value (value)
    // You can send this value to the server or perform any necessary action.
    console.log('Submitted value:', value);

    // Create a new form submission message and add it to the chat
    const newFormMessage = { text: `Forecast for ${value.choice} in event of ${value.text}`, user: true };
    setChatMessages((prevMessages) => [...prevMessages, newFormMessage]);

    // Clear the input field by calling the clearInput function
    clearInput();

    // Send the user input to the backend and update chat messages
    await sendMessage(value, true);
  };


  const sendMessage = async (text, isForm) => {
    if (isForm) {
      // Send the user input to the backend for plot
      const plotResponsePromise = fetch(`${API_BASE_URL}/api/send-plot/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text }),
      }).then((response) => response.json());

      // Send the user input to the backend for context
      const contextResponsePromise = fetch(`${API_BASE_URL}/api/send-context/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text }),
      }).then((response) => response.json());

      // Handle the context response as soon as it arrives
      contextResponsePromise.then((contextResponseData) => {
        // Update the frontend chat display with the context response
        const newContextResponse = { html: contextResponseData.html, user: false };
        setChatMessages((prevMessages) => [...prevMessages, newContextResponse]);

        // Scroll to the bottom of the chat
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
      });

      plotResponsePromise.then((plotResponseData) => {
        const plotData = plotResponseData.plot;
        const optimalPlotData = plotResponseData.optimal_plot;
        const weightsData = plotResponseData.weights;
        const optimalWeightsData = plotResponseData.optimal_weights;
        const error = plotResponseData.error;

        if (error) {
          // Update the frontend chat display with the response
          const newResponse = { html: error, user: false };
          setChatMessages((prevMessages) => [...prevMessages, newResponse]);
          // Scroll to the bottom of the chat
          chatRef.current.scrollTop = chatRef.current.scrollHeight;
        } else {

        // Create Highcharts chart options with the decoded image data
        const chartOptions = {
          chart: {
            type: 'line', // Specify the chart type you want
          },
          title: {
            text: 'Portfolio scenario based on given event',
            style: {
              fontSize: '16px' // Set the font size of the chart title
            }
          },
          xAxis: {
            title: {
              text: 'Months' // Set the label for the x-axis
            },
            min: 0, // Set the minimum value of the x-axis
            max: 13, // Set the maximum value of the x-axis
            tickInterval: 1 // Optional: Set the tick interval if needed
          },
          yAxis: {
            // Configuration for the Y-axis
          },
          colors: ['#7ED2CF', '#0098C6', '#1F4998'],
          plotOptions: {
            series: {
              marker: {
                enabled: false, // Disable markers for all series
                symbol: 'circle', // Set the marker symbol to 'circle' (dots) for all line series
                fillColor: '#f4faf4', // Set the background color
                fillOpacity: 0.2, // Adjust the opacity of the filled area
              }
            },
            line: {
              lineWidth: 3 // Set the default line thickness for all line series
            },
          },
          tooltip: {
            shared: true, // Enable shared tooltip
          },
          series: Object.keys(plotData).map((key) => ({
            name: key,
            data: plotData[key],
          })),
          // Additional Highcharts options as needed
        };

        // Set the chart options in state
        setOptimalChartOptions(chartOptions);

        // Render the Highcharts chart inside the chat block
        const plotHtmlBlock = (
          <div>
            <span>Here is a representation of the expected scenarios of your portfolio based on the provided event:</span>
            <br></br><br></br>
            <div id="chart-container">
              {chartOptions && (
                <HighchartsReact highcharts={Highcharts} options={chartOptions} />
              )}
            </div>
          </div>
        );

        // Update the frontend chat display with each block separately
        const newPlotResponse = { plot: plotHtmlBlock, user: false };
        setChatMessages((prevMessages) => [...prevMessages, newPlotResponse]);

        // Create Highcharts chart options with the decoded image data
        const optimalChartOptions = {
          chart: {
            type: 'line', // Specify the chart type you want
          },
          title: {
            text: 'Portfolio scenario based on optimal weights',
            style: {
              fontSize: '16px' // Set the font size of the chart title
            }
          },
          xAxis: {
            title: {
              text: 'Months' // Set the label for the x-axis
            },
            min: 0, // Set the minimum value of the x-axis
            max: 13, // Set the maximum value of the x-axis
            tickInterval: 1 // Optional: Set the tick interval if needed
          },
          yAxis: {
            // Configuration for the Y-axis
          },
          colors: ['#7ED2CF', '#0098C6', '#1F4998'],
          plotOptions: {
            series: {
              marker: {
                enabled: false, // Disable markers for all series
                symbol: 'circle', // Set the marker symbol to 'circle' (dots) for all line series
                fillColor: '#f4faf4', // Set the background color
                fillOpacity: 0.2, // Adjust the opacity of the filled area
              }
            },
            line: {
              lineWidth: 3 // Set the default line thickness for all line series
            },
          },
          tooltip: {
            shared: true, // Enable shared tooltip
          },
          series: Object.keys(optimalPlotData).map((key) => ({
            name: key,
            data: optimalPlotData[key],
          })),
          // Additional Highcharts options as needed
        };

        // Set the chart options in state
        setOptimalChartOptions(optimalChartOptions);

        // Render the Highcharts chart inside the chat block
        const optimalHtmlBlock = (
          <div>
            <span>In case of the realisation of this event, these are the optimised weights for your portfolio</span>
            <br></br><br></br>
            <div>
              <OptimalWeights weights={weightsData} optimalWeights={optimalWeightsData} />
            </div>
            <br></br><br></br>
            <span>Here are the scenarios of the evolution of your portfolio with optimised weights:</span>
            <br></br><br></br>
            <div id="chart-container">
              {optimalChartOptions && (
                <HighchartsReact highcharts={Highcharts} options={optimalChartOptions} />
              )}
            </div>
          </div>
        );

        // Update the frontend chat display with each block separately
        const newOptimalPlotResponse = { plot: optimalHtmlBlock, user: false };
        setChatMessages((prevMessages) => [...prevMessages, newOptimalPlotResponse]);

        // Call the function to set up the Highcharts demo chart
        //setupHighcharts();
      }})
      .catch((error) => {
        console.error('Error fetching or rendering chart data:', error);
        }).finally(() => {
        setisLoading(false);
      });
    } else {
      // Send the user input to the backend for normal message
      const response = await fetch(`${API_BASE_URL}/api/send-message/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text }),
      });

      const responseData = await response.json();

      // Update the frontend chat display with the response
      const newResponse = { html: responseData.html, user: false };
      setChatMessages((prevMessages) => [...prevMessages, newResponse]);

      // Scroll to the bottom of the chat
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  };

  const toggleWeightForm = () => {
    setIsWeightFormVisible(!isWeightFormVisible); // Toggle the visibility state
  };

  // Scroll to the bottom of the chat when new messages arrive
  useEffect(() => {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [chatMessages]);

  return (
    <>
    <TopPanel/>
    <div className="App">
      <div className='main-container'>
        {
          <div >
            {chatMessages.length === 0 &&
                <div className='messageIconText'>
                  <img src={messageIcon} class='messageIcon'/>
                  <p>No questions added</p>
                  <p>Provide an event to our AI model to give you predictions and optimisations tailored to your profile based on that event.</p>
                </div>
            }
            <div className="chat" ref={chatRef}>
             {isLoading && (
                 <div className="message bot">
                   <div className="message-content"><CircularProgress/></div>
                 </div>
             )}
              {chatMessages.slice().reverse().map((message, index) => (
                  <div key={index} className={`message ${message.user ? 'user' : 'bot'}`}>
                    {message.plot ? (
                        <div className="message-content">{message.plot}</div>
                    ) : message.html ? (
                        <div className="message-content" dangerouslySetInnerHTML={{ __html: message.html }}></div>
                    ) : (
                        <div className="message-content">{message.text}</div>
                    )}
                  </div>
              ))}
            </div>
            <div className="input-container">
              {/* Toggle button */}
              {/* <ToggleSwitch isChatMode={isChatMode} onClick={toggleMode} /> */}
              {isChatMode ? (
                  <>
                    <textarea
                        rows="1"
                        value={inputText}
                        onChange={(e) => {
                          setInputText(e.target.value);
                          handleInputHeightChange(e); // Adjust height as the user types
                        }}
                        onKeyDown={handleKeyPress}
                        placeholder="Enter your message..."
                    />
                    <button onClick={handleSend}>Send</button>
                  </>
              ) : (
                  <>
                    {/* <InputForm onSubmit={handleFormSubmit} onToggleWeightForm={toggleWeightForm} /> */}
                  </>
              )}
            </div>
            <div style={{display:'flex',justifyContent:'center', alignContent:'center',height:'200px'}}>
              <StressTestInputForm onSubmit={handleFormSubmit} onToggleWeightForm={toggleWeightForm} isLoading={isLoading}/>
            </div>
          </div>
        }
      </div>
    </div>
    </>
  );
}

export default App;
