import React from 'react';

function OptimalWeights({ weights, optimalWeights }) {
  const optimizedWeightsList = (
    <ul>
      {Object.entries(optimalWeights).map(([key, value]) => {
        let arrow = '';
        let color = '';

        if (weights.hasOwnProperty(key)) {
          if (value > weights[key]) {
            arrow = '↑'; // Up arrow (↑)
            color = 'green';
          } else if (value < weights[key]) {
            arrow = '↓'; // Down arrow (↓)
            color = 'red';
          }
        }

        value = Math.round(value * 100);

        return (
          <li key={key}>
            {key}: <span style={{ color }}>{value}% {arrow}</span>
          </li>
        );
      })}
    </ul>
  );

  return <div>{optimizedWeightsList}</div>;
}

export default OptimalWeights;
