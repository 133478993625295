import './TopPanel.css';
import logo from '../assets/logos/ciosLogo.png';

const TopPanel = ()=> {
    return (
        <div className='top-panel'>
            <img src={logo} alt='logo' className='logo'/>
            <div className='menu'>
           
          
            <p>Startseite</p>
            <p>Kunden</p>
            <p>Verwaltungsbereich</p>
        </div>
        </div>      
    )
}
export default TopPanel;